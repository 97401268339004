import React from "react"
import DownloadAppPage from "../components/DownloadAppPage/UpSalePage"
import LandingLayout from "../components/Layout/LandingLayout"
import { useStaticQuery } from "gatsby"

const DownloadApp = () => {

    const data = useStaticQuery(graphql`
    query {
      ogImage: file(relativePath: { eq: "og-image-app-page.png" }) {
        id
        childImageSharp {
          fixed {
            src
            height
            width
          }
        }
      }
    }
  `)

  const {
    ogImage: { childImageSharp },
  } = data
    return(
  <LandingLayout
    seoProps={{
      title: "FREE Herb Encyclopedia Mobile App for iPhone and Android",
      image: childImageSharp.fixed,
    }}
  >
    <DownloadAppPage />
  </LandingLayout>)
}

export default DownloadApp
