import styled from 'styled-components';


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
    padding-top: 80px;
`

export const BackdropSection = styled.div`
    display: flex;
    justify-content: center;
    padding-top: var(--LandingSectionPaddingTopBottom);
    padding-bottom: var(--LandingSectionPaddingTopBottom);
`

