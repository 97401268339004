import React from "react"
import { theme } from "../Layout/GlobalStyle"
import HeroSection from "../Layout/LandingLayout/HeroSection"
import { Container, BackdropSection } from "./styles"
import {graphql, useStaticQuery} from 'gatsby'

function DownloadAppPage() {

  const data = useStaticQuery(graphql`
    query
      {
        pictureOne: file(relativePath: {eq: "app_splash_images/welcome-to-findanherb-splash.png"}) {
          id
          size
          relativePath
          childImageSharp {
            id
            fixed(jpegQuality: 100, jpegProgressive: true, quality: 100, pngQuality: 100, width: 1000) {
              src
              srcSet
              originalName
              base64
            }
          }
        }
       
      }
      
    
  `)

  
  const landingHeroImage = data.pictureOne.childImageSharp.fixed.src


  return (
    <Container>
      <BackdropSection >
        <HeroSection 
        
        description={"FindanHerb offers a wide-ranging database of symptoms to help pinpoint what you need. From headaches to arthritis and everything in between. Join the app to gain access to a growing herb encyclopedia and health community."} 

        columnImageSrc={landingHeroImage} heading="Get the app!" subheading="You won't regret it" />
      </BackdropSection>
    </Container>
  )
}

export default DownloadAppPage
